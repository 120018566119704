import axios from "axios"
import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import CloseButton from "../../../components/CloseButton"
import { ACCOUNT_LOGO, APPOINTMENTS_LOGO, BASE_URL, CUSTOMERS_LOGO_FOCUS, HOME_LOGO, RELATIVE_PATH_PLATFORM, RELATIVE_PATH_PLATFORM_ACCOUNT, RELATIVE_PATH_PLATFORM_APPOINTMENTS, RELATIVE_PATH_PLATFORM_CUSTOMERS, RELATIVE_PATH_PLATFORM_HOME } from "../../../utils/Constants"
import NavBar from "../components/NavBar"
import { Patient } from "../components/Patient"

const NewHealthPlan = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const userId = location.state.userId
    const idToken = location.state.idToken
    const patient: Patient = location.state.patient
    const email = location.state.email

    const [isLoading, setIsLoading] = useState(false)

    // Actions to perform when clicking on Navbar logos and Holifya icon
    const onHolifyaLogoClick = () => {
        navigate(RELATIVE_PATH_PLATFORM_HOME, { state: { userId, idToken } })
    }
    const onHomeIconClick = () => {
        navigate(RELATIVE_PATH_PLATFORM, { state: { userId, idToken } })
    }
    const onCustomersIconClick = () => {
        navigate(RELATIVE_PATH_PLATFORM_CUSTOMERS, { state: { userId, idToken } })
    }
    const onApointmentsIconClick = () => {
        navigate(RELATIVE_PATH_PLATFORM_APPOINTMENTS, { state: { userId, idToken, email } })
    }
    const onAccountIconClick = () => {
        navigate(RELATIVE_PATH_PLATFORM_ACCOUNT, { state: { userId, idToken } })
    }

    const [topNutritionRecommendations, setTopNutritionRecommendations] = useState("")
    const [topHeroFoods, setTopHeroFoods] = useState("")
    const [topIntegratori, setTopIntegratori] = useState("")

    const isPublishButtonDisabled = topNutritionRecommendations.length === 0 && topHeroFoods.length == 0 && topIntegratori.length === 0

    // Convert text input to an array of strings, trimming each line and ignoring empty lines
    const convertToArray = (text: string): string[] => {
        return text
            .split("\n")
            .map(line => line.trim())
            .filter(line => line.length > 0); // Remove empty lines
    };

    // Prefix each line with a bullet point "• " if not already present
    const addBullets = (text: string): string => {
        return text
            .split("\n")
            .map(line => line.trim().startsWith("•") ? line : `• ${line}`)
            .join("\n");
    };

    // Handlers for each text area
    const handleNutritionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTopNutritionRecommendations(e.target.value); // Save raw input without bullets
    };

    const handleHeroFoodsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTopHeroFoods(e.target.value);
    };

    const handleIntegratoriChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTopIntegratori(e.target.value);
    };

    const onPublishButtonClick = () => {
        const nutritionArray = convertToArray(topNutritionRecommendations);
        const heroFoodsArray = convertToArray(topHeroFoods);
        const integratoriArray = convertToArray(topIntegratori);

        callCreateHealthPlanApi(nutritionArray, heroFoodsArray, integratoriArray)
    }

    async function callCreateHealthPlanApi(
        nutritionRecommendations: string[],
        heroFoods: string[],
        integratori: string[]
    ) {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/health-plans`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken!,
            },
            body: JSON.stringify(
                {
                    user_id: patient.userId,
                    data: {
                        nutrition_recommendations: nutritionRecommendations,
                        top_hero_foods: heroFoods,
                        top_integratori: integratori
                    },
                    user_email: patient.email,
                    user_name: patient.name,
                }
            )
        })
        setIsLoading(false)
        if (response.ok) {
            const responseJson = await response.json()
            healthPlanFile && callGetUrlApi(responseJson.plan_id, "health_plan/health_plan", healthPlanFile)
            dietFile && callGetUrlApi(responseJson.plan_id, "health_plan/diet", dietFile)
            shoppingListFile && callGetUrlApi(responseJson.plan_id, "health_plan/shopping_list", shoppingListFile)
            habitsFile && callGetUrlApi(responseJson.plan_id, "health_plan/eating_habits", habitsFile)
            tipsAndRecepesFile && callGetUrlApi(responseJson.plan_id, "health_plan/tips_and_recepes", tipsAndRecepesFile)
            fruitsAndVegetablesFile && callGetUrlApi(responseJson.plan_id, "health_plan/fruits_and_vegetables", fruitsAndVegetablesFile)
        }
    }

    const requestOptionsGET = {
        method: 'GET',
        headers: {
            'Authorization': idToken!
        }
    }

    async function callGetUrlApi(id: string, folder: string, uploadedFile: File) {
        setIsLoading(true)
        const response = await fetch(`${BASE_URL}/api/health-plans/patient/${patient.userId}/upload-url?filename=${id}.pdf&folder=${folder}`, requestOptionsGET)
        if (response.ok) {
            const urlResponse = await response.json()
            const formData = new FormData()
            Object.entries(urlResponse.fields).forEach(([k, v]) => {
                formData.append(k, v as unknown as string)
            })
            formData.append('file', uploadedFile)
            const s3response = await axios.post(urlResponse.url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            setIsLoading(false)
            if (s3response.status === 204) {
                console.log("File uploaded successfully")
                navigate("/platform-experts/customers/single-patient-page", { state: { patient, userId, idToken, email } })
            } else {
                console.log("File not uploaded")
            }
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
        }
    }

    const [healthPlanFile, setHealthPlanFile] = useState<File>()
    const [dietFile, setDietFile] = useState<File>()
    const [shoppingListFile, setShoppingListFile] = useState<File>()
    const [habitsFile, setHabitsFile] = useState<File>()
    const [tipsAndRecepesFile, setTipsAndRecepesFile] = useState<File>()
    const [fruitsAndVegetablesFile, setFruitsAndVegetablesFile] = useState<File>()

    const onHealthPlanInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true)
        const files = (e.target as HTMLInputElement).files
        setHealthPlanFile(files![0])
        setIsLoading(false)
    }

    const onHealthPlanInputRemove = () => {
        setIsLoading(true)
        setHealthPlanFile(undefined)
        setIsLoading(false)
    }

    const onDietInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true)
        const files = (e.target as HTMLInputElement).files
        setDietFile(files![0])
        setIsLoading(false)
    }

    const onDietInputRemove = () => {
        setIsLoading(true)
        setDietFile(undefined)
        setIsLoading(false)
    }

    const onShoppingListInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true)
        const files = (e.target as HTMLInputElement).files
        setShoppingListFile(files![0])
        setIsLoading(false)
    }

    const onShoppingListInputRemove = () => {
        setIsLoading(true)
        setShoppingListFile(undefined)
        setIsLoading(false)
    }

    const onHabitsInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true)
        const files = (e.target as HTMLInputElement).files
        setHabitsFile(files![0])
        setIsLoading(false)
    }

    const onHabitsInputRemove = () => {
        setIsLoading(true)
        setHabitsFile(undefined)
        setIsLoading(false)
    }

    const onTipsAndRecepesInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true)
        const files = (e.target as HTMLInputElement).files
        setTipsAndRecepesFile(files![0])
        setIsLoading(false)
    }

    const onTipsAndRecepesInputRemove = () => {
        setIsLoading(true)
        setTipsAndRecepesFile(undefined)
        setIsLoading(false)
    }

    const onFruitsAndVegetablesInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true)
        const files = (e.target as HTMLInputElement).files
        setFruitsAndVegetablesFile(files![0])
        setIsLoading(false)
    }

    const onFruitsAndVegetablesInputRemove = () => {
        setIsLoading(true)
        setFruitsAndVegetablesFile(undefined)
        setIsLoading(false)
    }

    // ********************************************************************************

    return (
        <div className='App Platform relative'>
            <NavBar
                onHolifyaLogoClick={onHolifyaLogoClick}
                homeLogo={HOME_LOGO}
                onHomeIconClick={onHomeIconClick}
                customersLogo={CUSTOMERS_LOGO_FOCUS}
                onCustomersIconClick={onCustomersIconClick}
                apointmentsLogo={APPOINTMENTS_LOGO}
                onApointmentsIconClick={onApointmentsIconClick}
                accountLogo={ACCOUNT_LOGO}
                onAccountIconClick={onAccountIconClick}
                isLoading={isLoading}
            />
            <div className={`platform-experts-no-navbar-container relative ${(isLoading) ? "visible-0-percent inactive" : ""}`}>
                <div className={`platform-experts-customers-single-patient-create-plan-container`}>
                    <div
                        className="platform-experts-customers-single-patient-create-plan-title-row mb-[20px]"
                    >
                        <div className="platform-experts-customers-single-patient-create-plan-title-row-left-element">
                            <div className="platform-experts-customers-single-patient-create-plan-title-row-patient-name">
                                {patient.name} {patient.familyName}
                            </div>
                            <div className="platform-experts-customers-single-patient-create-plan-title-row-heading">
                                Crea nuovo health plan
                            </div>
                        </div>
                        <CloseButton
                            onClick={() => navigate("/platform-experts/customers/single-patient-page", { state: { patient, userId, idToken, email } })}
                        />
                    </div>
                    <div className="w-full h-[calc(100%-220px)] overflow-y-auto scrollbar-hide">
                        <div className="w-[89%] h-full grid grid-cols-2 gap-4" style={{ gridAutoRows: "1fr" }}>
                            <div className="w-full h-full">
                                <div className="font-bold text-[14px] leading-[17px] mb-[20px]">Top nutrition recommendations</div>
                                <div className="relative flex-grow w-full h-[calc(100%-37px)] rounded-[20px] border border-solid border-black p-[20px]">
                                    <textarea
                                        className="w-full h-full resize-none border-none focus:outline-none"
                                        value={addBullets(topNutritionRecommendations)} // Display the bullet-prefixed lines
                                        onChange={handleNutritionChange} // Handle input changes
                                    />
                                </div>
                            </div>
                            <div className="w-full h-full">
                                <div className="font-bold text-[14px] leading-[17px] mb-[20px]">Top hero foods</div>
                                <div className="relative flex-grow w-full h-[calc(100%-37px)] rounded-[20px] border border-solid border-black p-[20px]">
                                    <textarea
                                        className="w-full h-full resize-none border-none focus:outline-none"
                                        value={addBullets(topHeroFoods)} // Display the bullet-prefixed lines
                                        onChange={handleHeroFoodsChange} // Handle input changes
                                    />
                                </div>
                            </div>
                            <div className="w-full h-full">
                                <div className="font-bold text-[14px] leading-[17px] mb-[20px]">Top integratori</div>
                                <div className="relative flex-grow w-full h-[calc(100%-37px)] rounded-[20px] border border-solid border-black p-[20px]">
                                    <textarea
                                        className="w-full h-full resize-none border-none focus:outline-none"
                                        value={addBullets(topIntegratori)} // Display the bullet-prefixed lines
                                        onChange={handleIntegratoriChange} // Handle input changes
                                    />
                                </div>
                            </div>
                            <div className="w-full h-full">
                                <div className="font-bold text-[14px] leading-[17px] mb-[20px]">Zona caricamento pdf</div>
                                <div className="flex-grow">
                                    <UploadPdfCard
                                        pdfType={PdfType.HealthPlan}
                                        uploadedFile={healthPlanFile}
                                        onFileInputChange={onHealthPlanInputChange}
                                        onFileInputRemove={onHealthPlanInputRemove}
                                    />
                                    <UploadPdfCard
                                        pdfType={PdfType.Diet}
                                        uploadedFile={dietFile}
                                        onFileInputChange={onDietInputChange}
                                        onFileInputRemove={onDietInputRemove}
                                    />
                                    <UploadPdfCard
                                        pdfType={PdfType.ShoppingList}
                                        uploadedFile={shoppingListFile}
                                        onFileInputChange={onShoppingListInputChange}
                                        onFileInputRemove={onShoppingListInputRemove}
                                    />
                                    <UploadPdfCard
                                        pdfType={PdfType.EatingHabits}
                                        uploadedFile={habitsFile}
                                        onFileInputChange={onHabitsInputChange}
                                        onFileInputRemove={onHabitsInputRemove}
                                    />
                                    <UploadPdfCard
                                        pdfType={PdfType.TipsAndRecepes}
                                        uploadedFile={tipsAndRecepesFile}
                                        onFileInputChange={onTipsAndRecepesInputChange}
                                        onFileInputRemove={onTipsAndRecepesInputRemove}
                                    />
                                    <UploadPdfCard
                                        pdfType={PdfType.SeasonalFruitsAndVegetables}
                                        uploadedFile={fruitsAndVegetablesFile}
                                        onFileInputChange={onFruitsAndVegetablesInputChange}
                                        onFileInputRemove={onFruitsAndVegetablesInputRemove}
                                        isLast={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="platform-experts-customers-single-patient-create-plan-buttons-row">
                        <button
                            className="platform-experts-customers-single-patient-create-plan-undo-button standard-uppercase-text"
                            onClick={() => navigate("/platform-experts/customers/single-patient-page", { state: { patient, userId, idToken, email } })}
                        >
                            Annulla
                        </button>
                        <button
                            className={`platform-experts-customers-single-patient-create-plan-publish-button primary-action-color-background standard-uppercase-text ${isPublishButtonDisabled ? "" : "pointer"}`}
                            disabled={isPublishButtonDisabled}
                            onClick={onPublishButtonClick}
                        >
                            Pubblica
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewHealthPlan

export enum PdfType {
    HealthPlan = "health-plan",
    Diet = "diet",
    EatingHabits = "eating-habits",
    ShoppingList = "shopping-list",
    TipsAndRecepes = "tips-and-recepes",
    SeasonalFruitsAndVegetables = "seasonal-fruits-and-vegetables",
}

interface Props {
    pdfType: PdfType,
    uploadedFile: File | undefined,
    onFileInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onFileInputRemove: () => void,
    isLast?: boolean,
}

export const UploadPdfCard: React.FC<Props> = ({
    pdfType,
    uploadedFile,
    onFileInputChange,
    onFileInputRemove,
    isLast = false,
}) => {
    const handlePdfName = () => {
        switch (pdfType) {
            case PdfType.HealthPlan:
                return "health plan"
            case PdfType.Diet:
                return "dieta"
            case PdfType.ShoppingList:
                return "lista della spesa"
            case PdfType.EatingHabits:
                return "abitudini alimentari"
            case PdfType.TipsAndRecepes:
                return "consigli e ricette"
            case PdfType.SeasonalFruitsAndVegetables:
                return "frutta e verdura di stagione"
            default:
                return ""
        }
    }

    const handlePdfUploadedText = () => {
        switch (pdfType) {
            case PdfType.HealthPlan:
                return "Health plan caricato"
            case PdfType.Diet:
                return "Dieta caricata"
            case PdfType.ShoppingList:
                return "Lista della spesa caricata"
            case PdfType.EatingHabits:
                return "Abitudini alimentari caricate"
            case PdfType.TipsAndRecepes:
                return "Consigli e ricette caricati"
            case PdfType.SeasonalFruitsAndVegetables:
                return "Frutta e verdura di stagione caricate"
            default:
                return ""
        }
    }

    // ********************************************************************************

    return (
        <div className={`w-full h-[150px] rounded-[20px] border border-solid border-[#d9d9d9] shadow-platform flex items-center justify-center ${!isLast && "mb-[20px]"} ${uploadedFile !== undefined && "bg-[#DCEAFF]"}`}>
            <div className="w-[90%] h-[80%] flex items-center justify-between">
                <div className="font-bold text-[20px] leading-[40px]">
                    {uploadedFile ? `${handlePdfUploadedText()}` : `Allega ${handlePdfName()}`}
                </div>
                {
                    uploadedFile ? <div
                        className="flex items-center cursor-pointer"
                        onClick={onFileInputRemove}
                    >
                        <div className="upload-plan-widget-x-image">
                            <img src="/images/symbols/x.svg" />
                        </div>
                        <div
                            className="font-bold text-[14px] uppercase"
                        >
                            Rimuovi
                        </div>
                    </div> :
                        <div>
                            <div
                                className="font-bold text-[14px] uppercase cursor-pointer"
                                onClick={() => (document.querySelector(`.file-input-box-${pdfType}`) as HTMLElement).click()}
                            >
                                Carica e sostiuisci
                            </div>
                            <input
                                type="file"
                                accept="application/pdf image/jpg image/jpeg"
                                className={`file-input-box-${pdfType}`}
                                onChange={onFileInputChange}
                                hidden
                            />
                        </div>
                }
            </div>
        </div>
    )
}
