import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import LoadingSpinner from "../../components/LoadingSpinner"
import { ACCOUNT_LOGO, APPOINTMENTS_LOGO, BASE_URL, CUSTOMERS_LOGO_FOCUS, HOME_LOGO, RELATIVE_PATH_PLATFORM, RELATIVE_PATH_PLATFORM_ACCOUNT, RELATIVE_PATH_PLATFORM_APPOINTMENTS, RELATIVE_PATH_PLATFORM_CUSTOMERS, RELATIVE_PATH_PLATFORM_HOME } from "../../utils/Constants"
import { OrderType } from "../../utils/Enums"
import { handleOrderType } from "../../utils/Functions"
import NavBar from "./components/NavBar"
import { Patient } from "./components/Patient"
import "./css/Customers.css"
import CustomerWidget from "./widgets/CustomerWidget"

const Customers = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const userId = location.state.userId
  const idToken = location.state.idToken

  const [isLoading, setIsLoading] = useState(false)

  const [email, setEmail] = useState("")

  // Actions to perform when clicking on Navbar logos and Holifya icon
  const onHolifyaLogoClick = () => {
    navigate(RELATIVE_PATH_PLATFORM_HOME, { state: { userId, idToken } })
  }
  const onHomeIconClick = () => {
    navigate(RELATIVE_PATH_PLATFORM, { state: { userId, idToken } })
  }
  const onCustomersIconClick = () => {
    navigate(RELATIVE_PATH_PLATFORM_CUSTOMERS, { state: { userId, idToken } })
  }
  const onApointmentsIconClick = () => {
    navigate(RELATIVE_PATH_PLATFORM_APPOINTMENTS, { state: { userId, idToken, email } })
  }
  const onAccountIconClick = () => {
    navigate(RELATIVE_PATH_PLATFORM_ACCOUNT, { state: { userId, idToken } })
  }

  const [filteredName, setFilteredName] = useState("")
  const [filteredEmail, setFilteredEmail] = useState("")
  const [filtredSwabCode, setFilteredSwabCode] = useState("")

  const searchButtonDisabled = filteredName === "" && filteredEmail === "" && filtredSwabCode === ""

  const [filterApplied, setFilterApplied] = useState(false)

  const onSearchClick = () => {
    setFilterApplied(true)
    callGetFilteredPatientsApi()
  }

  const onEraseFilterClick = () => {
    setFilteredName("")
    setFilteredEmail("")
    setFilteredSwabCode("")
    callGetPatientsNoRoleApi()
    setFilterApplied(false)
  }

  const patients = [
    {
      name: "placeholder",
      familyName: "placeholder",
      email: "placeholder",
      swabCode: "placeholder",
      orderType: OrderType.DNATest,
      userId: "",
      mobilePhone: "",
    }
  ]

  const [filteredPatients, setFilteredPatients] = useState(patients)

  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
    callGetMedicalUserApi()
  }, [])

  const requestOptionsGET = {
    method: 'GET',
    headers: {
      'Authorization': idToken!
    }
  }

  // GET all patients by user_id
  async function callGetPatientsApi(role: string) {
    const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/${userId}/patients/search?role=${role}`, requestOptionsGET)
    if (responseUserinfo.ok) {
      const responseJson = await responseUserinfo.json()
      const newPatients: Patient[] = []
      for (const patient of responseJson) {
        newPatients.push({
          name: patient.data.name,
          familyName: patient.data.family_name,
          email: patient.email,
          swabCode: patient.swabcode,
          orderType: handleOrderType(patient.confirmation_number, patient.status, patient.items !== null ? patient.items[0].type : undefined),
          userId: patient.user_id,
          mobilePhone: patient.data.mobile_phone,
        })
      }
      setFilteredPatients(newPatients)
    } else if (responseUserinfo.status === 401) {
      // call refreshToken
      navigate("/login")
    } else {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
    }
  }

  // GET all patients by user_id
  async function callGetPatientsNoRoleApi() {
    setIsLoading(true)
    const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/${userId}/patients/search?role=${expertRole}`, requestOptionsGET)
    setIsLoading(false)
    if (responseUserinfo.ok) {
      const responseJson = await responseUserinfo.json()
      const newPatients: Patient[] = []
      for (const patient of responseJson) {
        newPatients.push({
          name: patient.data.name,
          familyName: patient.data.family_name,
          email: patient.email,
          swabCode: patient.swabcode,
          orderType: handleOrderType(patient.confirmation_number, patient.status, patient.items !== null ? patient.items[0].type : undefined),
          userId: patient.user_id,
          mobilePhone: patient.data.mobile_phone,
        })
      }
      setFilteredPatients(newPatients)
    } else if (responseUserinfo.status === 401) {
      // call refreshToken
      navigate("/login")
    } else {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
    }
  }

  // GET filtered patients by user_id
  async function callGetFilteredPatientsApi() {
    console.log(`name: ${filteredName}`)
    console.log(`email: ${filteredEmail}`)
    console.log(`swabcode: ${filtredSwabCode}`)
    setIsLoading(true)
    const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/${userId}/patients/search?name=${filteredName}&email=${filteredEmail}&swabcode=${filtredSwabCode}&role=${expertRole}`, requestOptionsGET)
    setIsLoading(false)
    if (responseUserinfo.ok) {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
      const newPatients: Patient[] = []
      for (const patient of responseJson) {
        newPatients.push({
          name: patient.data.name,
          familyName: patient.data.family_name,
          email: patient.email,
          swabCode: patient.swabcode,
          orderType: handleOrderType(patient.confirmation_number, patient.status, patient.items !== null ? patient.items[0].type : undefined),
          userId: patient.user_id,
          mobilePhone: patient.data.mobile_phone,
        })
      }
      setFilteredPatients(newPatients)
    } else if (responseUserinfo.status === 401) {
      // call refreshToken
      navigate("/login")
    } else {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
    }
  }

  const [expertRole, setExpertRole] = useState("")

  // GET all patients by user_id
  async function callGetMedicalUserApi() {
    const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/${userId}`, requestOptionsGET)
    if (responseUserinfo.ok) {
      const responseJson = await responseUserinfo.json()
      setEmail(responseJson.email)
      setExpertRole(responseJson.role)
      callGetPatientsApi(responseJson.role)
    } else if (responseUserinfo.status === 401) {
      // call refreshToken
      navigate("/login")
    } else {
      const responseJson = await responseUserinfo.json()
      console.log(responseJson)
    }
  }

  return (
    <div className='App Platform relative'>
      <NavBar
        onHolifyaLogoClick={onHolifyaLogoClick}
        homeLogo={HOME_LOGO}
        onHomeIconClick={onHomeIconClick}
        customersLogo={CUSTOMERS_LOGO_FOCUS}
        onCustomersIconClick={onCustomersIconClick}
        apointmentsLogo={APPOINTMENTS_LOGO}
        onApointmentsIconClick={onApointmentsIconClick}
        accountLogo={ACCOUNT_LOGO}
        onAccountIconClick={onAccountIconClick}
        isLoading={isLoading}
      />
      <div className={`platform-experts-no-navbar-container ${isLoading ? "visible-0-percent inactive" : ""}`}>
        <div className="platform-experts-dashboard-container">
          <div className="platform-experts-dashboard-title-row">
            <div className="platform-experts-dashboard-title-container">
              <div className="platform-experts-dashboard-title-logo-container">
                <img src="/images/platformLogos/customers-big-logo.svg" />
              </div>
              <div className="platform-experts-dashboard-title">
                Clienti
              </div>
            </div>
          </div>
          <div className="platform-experts-dashboard-content-container">
            <div className="platform-experts-final-element-container">
              <div className="customers-apply-filters-container">
                <div className="customers-apply-filters-internal-container">
                  <div className="customers-apply-filters-heading">
                    Cerca
                  </div>
                  <div className="customers-apply-filters-filters-container">
                    <div className="customers-apply-filters-single-filter-container">
                      <div className="customers-apply-filters-single-filter-label">
                        Nome
                      </div>
                      <input
                        className="customers-apply-filters-input-field"
                        placeholder="Cerca per Nome"
                        value={filteredName}
                        onChange={(e) => setFilteredName(e.target.value)}
                      />
                    </div>
                    <div className="customers-apply-filters-single-filter-container">
                      <div className="customers-apply-filters-single-filter-label">
                        Email
                      </div>
                      <input
                        className="customers-apply-filters-input-field"
                        placeholder="Cerca per indirizzo email"
                        value={filteredEmail}
                        onChange={(e) => setFilteredEmail(e.target.value)}
                      />
                    </div>
                    <div className="customers-apply-filters-single-filter-container">
                      <div className="customers-apply-filters-single-filter-label">
                        Codice campione
                      </div>
                      <input
                        className="customers-apply-filters-input-field"
                        placeholder="Cerca per codice campione"
                        value={filtredSwabCode}
                        onChange={(e) => setFilteredSwabCode(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="customers-apply-filters-buttons-row">
                    {
                      filterApplied ?
                        <button
                          className={`customers-erase-filters-button ternary-action-color-background pointer`}
                          onClick={onEraseFilterClick}
                        >
                          Azzera filtro
                        </button> : null
                    }
                    <button
                      className={`customers-apply-filters-button primary-action-color-background ${searchButtonDisabled ? "" : "pointer"}`}
                      onClick={onSearchClick}
                      disabled={searchButtonDisabled}
                    >
                      Cerca
                    </button>
                  </div>
                </div>
              </div>
              <div className="height-35" />
              {
                filteredPatients.map(patient => (
                  patient.name !== "placeholder" ?
                    <div key={patient.email}>
                      <CustomerWidget
                        patient={patient}
                        onCardClick={() => navigate("single-patient-page", { state: { userId, idToken, patient, email } })}
                      />
                      {patients.indexOf(patient) !== patients.length - 1 ? <div className="height-20" /> : null}
                    </div> : null
                ))
              }
            </div>
          </div>
        </div>
      </div>
      {
        isLoading ? <div className="width-100-percent height-100-percent center-div absolute"><LoadingSpinner /></div> : null
      }
    </div>
  )
}

export default Customers
